import React from "react";
import NewsCard from "../components/NewsCard";

function NewsUpdates() {
  const newslist = [
    {
      id: 1,
      title: "Men Christian fellowship",
      description:
        "MCF monthly meeting holds on the 2nd Sunday at the Church hall, immediately after the 8:00am service. Please plan to attend",
      image: "https://images.unsplash.com/photo-1594321570326-9e5ad26a19a4",
    },
    {
      id: 2,
      title: "SJM Creche",
      description:
        "SJM Creche is now operational. Share the good news with your neighbours please",
      image: "https://images.unsplash.com/photo-1594321610209-b0f463805378",
    },
  ];
  return (
    <div
      id="news"
      className="bg-white text-white h-max p-6 min-h-[430px]  grid gap-3 place-items-center mb-8"
    >
      <div className="grid gap-3 place-items-center mt-32 w-11/12 md:w-9/12">
        <div className="w-3/5 grid gap-2">
          <p className="text-3xl text-black heading text-center">News Update</p>
        </div>
        <div className="pt-10 border-t border-[#EC5929]">
          <div className="text-black grid grid-cols-1 lg:grid-cols-2 gap-8">
            {newslist.map((news) => {
              return (
                <NewsCard
                  key={news.id}
                  title={news.title}
                  description={news.description}
                  image={news.image}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsUpdates;
