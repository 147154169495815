import React from "react";

function Anthem() {
  return (
    <div
      id="anthem"
      className="h-max p-3 min-h-[410px] grid place-items-center mb-8"
    >
      <div className="flex flex-col mt-32 items-center gap-[1.5rem] text-black w-11/12 md:w-9/12">
        <div className="grid place-items-center gap-2 sm:mb-4">
          <p className="text-black text-3xl heading text-center">
            Parish Anthem
          </p>
          <div className="bg-[#EC5929] h-1 w-10 rounded-md"></div>
        </div>
        <div className="flex gap-8 justify-center">
          <div className="flex flex-col justify-center text-center gap-4 items-center text-sm leading-[32px] w-5/6 lg:w-3/6">
            <p>
              1. St. Jude's Church Magodo Lagos,
              Begotten of St. Jude Thaddaues
              Kinsman, Apostle of Christ Jesus,
              Patron, Saint of all hopeless souls,
              Friend of pilgirms and giver of hope
            </p>
            <div className="flex flex-col gap-2 my-4 leading-[20px]">
              <span className="font-bold">Refrain</span>
              <p className="font-medium italic">
                Your selfless banner with joy we hoist, spread Gospel of Christ
                discard all hate.
              </p>
            </div>
            <p>
              2. We declare that in this our Church we'll always tread the St. Jude's path
              Preaching, teaching the Word of Christ
              Saving souls of all those enslaved
              Helping orphans and those in need
            </p>
           <div className="flex flex-col gap-2 my-4 leading-[20px]">
              <span className="font-bold">Refrain</span>
              <p className="font-medium italic">
                Your selfless banner with joy we hoist,
                spread Gospel of Christ discard all hate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Anthem;
