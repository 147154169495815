import React, { useEffect, useState } from "react";
import svg_arrow from "../images/svgs/arrow-pointing-to-up-svgrepo-com.svg";

function Gallery() {
  const [activeTab, setActiveTab] = useState(1);
  const [preloadedImages, setPreloadedImages] = useState({});

  const galleryData = [
    {
      id: 1,
      title: "Mother's Day 2024",
      link: "https://photos.google.com/share/AF1QipOlEsgBUlD_5tluhUeXT8c66OZpbuxPmLQZM5icJsQ8BD-4mRkY0TSBM6k3BwAwPg?key=SGMtTURKeEZfR1pJbUNaMzljSzBxNnh4SUI3alF3"
    },
    // {
    //   id: 2,
    //   title: "Father's Day 2024",
    //   link: ""
    // },
    {
      id: 3,
      title: "Open Air Crusade",
      link: "https://photos.app.goo.gl/QKEbW3axuGS4kP286"
    },
    {
      id: 4,
      title: "Juvenile Harvest 2023",
      link: "https://photos.app.goo.gl/RwYXVKd6Nbh6yehj9"
    },
    {
      id: 5,
      title: "Old School vs New School Football Match 2023",
      link: "https://photos.app.goo.gl/BpD5EBHA2ovk2Drp6"
    },
    {
      id: 6,
      title: "St Jude's Anglican Church Magodo - 2023 Father's Day (360' Videos)",
      link: "https://photos.app.goo.gl/pgPvEDUbgdPsuxrj9"
    },
    {
      id: 7,
      title: "Vicar’s Timeout with St Jude’s Anglican Church Magodo Youths 2023",
      link: "https://photos.app.goo.gl/kg9wxDsJTW1kfE4AA"
    },
    {
      id: 8,
      title: "2023 Rogation @ St Jude's Anglican Church Magodo",
      link: "https://photos.app.goo.gl/gshjNhMxR5sYnzeb9"
    },
    {
      id: 9,
      title: "St Jude's Anglican Church Magodo 2023 Easter Monday",
      link: "https://photos.app.goo.gl/q4Y7H2xWFHyomC17A"
    },
    {
      id: 10,
      title: "2023 Palm Sunday",
      link: "https://photos.app.goo.gl/bYQriXPvVPHdF9Xm6"
    },
    {
      id: 11,
      title: "Mothering Sunday @ St Jude's Anglican Church Magodo 2023",
      link: "https://photos.app.goo.gl/uaLfQiW27n1Cc6pw8"
    },
    {
      id: 12,
      title: "2022 Youth Harvest @ St Jude's Anglican Church Magodo",
      link: "https://photos.app.goo.gl/UUkEim15nS9S3ogt5"
    }
  ];

  return (
    <div
      id="gallery"
      className="h-max p-3 min-h-[410px] grid place-items-center mb-8"
    >
      <div className="flex flex-col mt-32 items-center gap-[1.5rem] text-black w-11/12 md:w-9/12">
        <div className="grid gap-2 mb-4 place-items-center">
          <p className="text-3xl text-center text-black heading">Gallery</p>
          <div className="bg-[#EC5929] h-1 w-10 rounded-md"></div>
        </div>
        <div className="w-full ">
          <div className="grid gap-[1rem] xl:grid-cols-[auto_auto_auto] sm:grid-cols-[auto_auto] xl:grid-flow-row gallery___btn">
            {galleryData.map((item) => (
              <a
                key={item.id}
                className="flex gap-[.5rem] h-[4.5rem] w-[18rem] items-center transition-[150ms] justify-center"
                onClick={() => setActiveTab(item.id)}
                href={item.link}
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-center">
                {item.title}
                </span>
               <img className="h-[1rem] rotate-[40deg] w-[1rem]" src={svg_arrow} alt="" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
