import React from "react";
import { motion } from "framer-motion";

function Clergy({ right }) {
  const clergyData = [
    {
      id: 1,
      name: "The Venerable Akin Omoyajowo",
      tenure: "February 2023 - present",
      summary: "Venerable Akin Omoyajowo is currently serving the church with dedication and commitment. His leadership focuses on guiding the congregation in their spiritual growth and fostering a strong sense of community within the church and leading God's people to having love, generosity and faithful service.",
      image: "ven-omoyajowo.jpeg"
    },
    {
      id: 2,
      name: "(Supervising Vicar) The Venerable Sunday Owoyele",
      tenure: "September 2022 - February 2023",
      summary: "Venerable Sunday Oweyole served the church for a brief period, starting in September 2022 and ending in February 2023. Despite his short tenure, he made significant contributions to the spiritual life of the congregation. He focused on strengthening the community's faith and fostering unity within the church. His time in the church was marked by dedication and commitment to pastoral care.",
      image: "ven-owoyele.jpg"
    },
    {
      id: 3,
      name: "The Venerable Abiade Adeniji",
      tenure: "16 January 2014 - September 2022",
      summary: "Venerable Abiade Adeniji resumed duty as the vicar of the church on the 16th of January 2014. His mission from the onset was to complete the main church building that had witnessed a tenmonth delay since the turning of the sod. He mobilised the congregation and tapped into his network of well-wishers outside the church for the herculean task of constructing the new church building. To the glory of God, the task was accomplished within two and a half years and the Lord Bishop of the Diocese of Lagos West, the Right Reverend James Olusola Odedeji dedicated the new church on Tuesday 20 June 2017. Venerable Adeniji has devoted his time to the development of the spiritual lives of the congregation. He has also worked assiduously towards building bridges and mending relationships in the church to ensure that the family in Christ remains one in words and deeds. The ministry of Venerable Adeniji in our midst is an enduring testament to the common held notion that a priest shares his ministry with his spouse. Mrs. Elizabeth M. Adeniji proves to be a tireless worker in the church. Apart from being the vicar's wife, she is also a licensed Lay Reader, who discharges her duty as such to the fullest in the church; she adopts a hands-on approach to the affairs of the church, initiating the revival of the Boys and Girls Brigades in the church and driving the growth of the Christian Mothers and Women's Guild The list of priests who have assisted Venerable Adeniji in our church includes Canon S.O.K. Idowu and Reverends Azubuike Ahubelem, Peter Adegbite and Dr OgoOluwa Adeyemi – the last two being the current Assisting Priests.",
      image: "ven-adeniji.jpg"
    },
    {
      id: 4,
      name: "The Reverend Babatunde Adegoroye",
      tenure: "28 January 2009 – 20 August 2012",
      summary: "Reverend Adegoroye commenced his ministry in our church on Monday 20 August 2012. Notably, he introduced changes to worship such as chanting of the Psalms and Sung Eucharist. He was determined to take on the challenge of commencing the Millennium Church project, as he rallied parishioners in earnest. He established the Nehemiah Fund, which was a way of encouraging parishioners to make regular contributions towards the project rather than waiting for when they will have a huge sum of money to contribute. Reverend Adegoroye conducted the groundwork for the Millennium Church project, including the preparation of the site of the construction by demolishing the very first temporary building and the building housing the sexton and other church workers. His efforts paid off, as the Right Reverend James Odedeji (Bishop Coadjutor, as he then was) performed his first Episcopal assignment when he laid the foundation of the new church building on Saturday 16 March 2013. Reverend Adegoroye and his wife, Mrs Yetunde Adegoroye were transferred on the 16 of January 2014. The priests who assisted Reverend Adegoroye include Reverends Vincent Akindeinde, Gabriel Duduyegbe and Canon S.O.K. Idowu.",
      image: "rev-adegoroye.png"
    },
    {
      id: 5,
      name: "The Rev. Canon M. Abraham Odumuyiwa",
      tenure: "28 January 2009 – 20 August 2012",
      summary: "Canon M.A.O. Abraham-Odumuyiwa was the first resident vicar of the church, since all the past vicars were of the non-stipendiary status. He helped in the reinforcement of the weekly Bible Studies. Likewise, he helped to foster good relationship amongst parishioners. He was a man of faith who trusted in the ability of God to start the daunting project of commencing the Phase III of the project – the construction of the Millennium Church even when such belief was at variance with the financial situation of the church at the time. His tenure witnessed a round of ground-breaking donations to the church, such as the ultra-modern organ, giant generator, giant sized air-conditioners for the entire church etc., all of which were donated anonymously. These donations in part prepared the parishioners into taking up the gauntlet for the challenge of the construction of the Millennium Church building. The Reverends Kehinde Akinade, Gbenga Awofuwa, William C. Swanzy, Gabriel Duduyegbe, and Garba I. Rika at different times served as curates under Canon Abraham-Odumuyiwa. His wife Mrs. Grace Abraham-Odumuyiwa also played prominent roles in the church during the tenure of Canon Abraham",
      image: "rev-odumuyiwa.jpg"
    },
    {
      id: 6,
      name: "The Reverend Gbenga Fagbemi",
      tenure: "January 2006 – 25 January 2009",
      summary: "Reverend Gbenga Fagbemi resumed duties as the Vicar on the 15 January 2006. During his time, the church witnessed notable physical development. The vicarage project was completed during his tenure; the Right Reverend Peter Adebiyi, the Lord Bishop of the Diocese of Lagos West dedicated by the on Friday th the 4 of July 2008. Reverend Fagbemi superintended the internal provision of ceiling for the church building through the funds raised by the Christian Mothers, marble pulpit, lectern, baptismal font, and Priest chairs. Likewise, he saw to the soft furnishing of the new vicarage, partial fencing of the church premises etc.Reverend Fagbemi was ably assisted by his wife, Dr (Mrs) N. Fagbemi – a medical doctor. Reverend Fagbemi introduced Medial Sunday to the church, where medical personnel were invited to the church togive important talk on health matters; the church clinic was established, where basic medical services were provided, like checking for vital signs.",
      image: "rev-fagbemi.png"
    },
    {
      id: 7,
      name: "The Reverend Canon Oluwagbemiga Onayemi",
      tenure: "July 2001 – January 2006",
      summary: "Canon Oluwagbemiga Onayemi (as he then was) resumed as the vicar of the church in July 2001. He was noted for his zeal for evangelism, especially through pastoral visits to members of the church and the Revival Services/Crusades that were conducted outside the church premises. During his tenure, the Boys and Girls Brigade, as well as Boys Scout were established and enrolled. He encouraged many spiritual activities between the Elders and the Youths in the church. The church was well organised into functional units of committees and ministries during the tenure of Canon Onayemi.",
      image: "rev-onayemi.png"
    },
    {
      id: 8,
      name: "The Rev. Peju Oyefuga (now deceased)",
      tenure: "February 2001 - July 2001",
      summary: "Reverend Oyefuga (later Venerable) was in the church for a very brief period, starting as the second curate in the church and upon the retirement of Venerable Oyebolu became the vicar for a very short period of about one month.",
      image: ""
    },
    {
      id: 9,
      name: "The Venerable P. B. Oyebolu",
      tenure: "1997 - 2001",
      summary: "Reverend Oyebolu (as he then was) was such a tireless worker who put his hand to the plough and never looked back, in the task to develop the church. He came from the well-established and elitist Anglican Church of the Ascension, Opebi. His arrival coincided with the movement of the church from Shangisha to Magodo; there was no physical structure in Magodo other than a makeshift tent of 55ft by 35ft made with planks and rusted corrugated iron sheet. Reverend Oyebolu quickly tapped into the goodwill of the network of his friends and well-wishers to raise money to construct the first building of the church.",
      image: "ven-oyebolu.png"
    }
];

  right = false;

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="grid place-items-center"
    >
      <div className="flex flex-wrap items-center justify-center gap-4 mt-8 mb-3 sm:mt-4 md:mb-0">
        <h2 className="w-full text-center text-[#624736] header-lvl-2 font-PlayfairDisplay">
          Clergy
        </h2>
        <hr className="hr-line" />
      </div>
      <div className="flex flex-col w-full max-w-3xl gap-24 p-8 mt-6 sm:mt-12 md:max-w-6xl">
        {clergyData.map((item) => {
          if (item.id % 2 === 0) {
            return <ClergyMember right={true} key={item.id} data={item} />;
          } else {
            return <ClergyMember key={item.id} data={item} />;
          }
        })}
      </div>
    </motion.div>
  );
}

const getImage = (imageName) => {
  try {
    return require(`../images/clergy/${imageName}`);
  } catch (error) {
    return null; // Return null or a default image if the image is not found
  }
};

export default Clergy;

const ClergyMember = ({ data, right }) => {
  const imageUrl = getImage(data.image);
  return (
    <div className="flex flex-col flex-wrap items-start gap-4 lg:flex-row lg:items-center sm:gap-10">
      <div
        className={`w-full lg:w-max flex justify-center ${
          right ? "sm:justify-end" : "sm:justify-start"
        }`}
      >
        <div className="size-[150px] sm:size-[250px] bg-neutral-50 ring-2 ring-inset ring-neutral-100 rounded-lg">
        <img className="h-[100%] w-full object-contain" src={imageUrl} alt={data.name}/>
        </div>
      </div>
      <div className={`flex-1 4 ${right && "lg:order-first"}`}>
        <p
          className={`text-lg font-medium text-center ${
            right ? "sm:text-right" : "sm:text-left"
          } `}
        >
          {data.name}
        </p>
        <p
          className={`text-neutral-500 text-sm font-medium mb-2 text-center ${
            right ? "sm:text-right" : "sm:text-left"
          }`}
        >
          {data.tenure}
        </p>
        <hr />
        <p className="mt-4 text-sm leading-7">{data.summary}</p>
      </div>
    </div>
  );
};