import React, { useEffect, useState } from "react";
import Footer from "../sections/footer";
// import db from "../firebase";
// Object that contains all images to be used
import "../index.css";
import NewsCard from "../components/NewsCard";
import Collage from "../components/Collage";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Youth({ gallery, youthIntro }) {
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  const controlNavbar = () => {
    if (window.scrollY > 300) {
      // if scroll down hide
      setShow(true);
    } else if (window.scrollY <= 200) {
      // if scroll up show
      setShow(false);
    }
    // remember current page location to use in the next move
    setLastScrollY(window.scrollY);
  };
  useEffect(() => {
    const setImages = () => {
      setIsLoading(false);
    };
    setImages();
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  const newslist = [
    {
      id: 1,
      title: "Youth Harvest",
      description:
        "The Lagos State governor, Babajide Sanwo-Olu, as at August 1 has announced the reopening of worship centres in the state from August 7 at 50% capacity.",
      image: "https://images.unsplash.com/photo-1594321570326-9e5ad26a19a4",
    },
    {
      id: 2,
      title: "Men Christian fellowship",
      description:
        "MCF monthly meeting holds on the 2nd Sunday at the Church hall, immediately after the 8:00am service. Please plan to attend",
      image: "https://images.unsplash.com/photo-1594321570326-9e5ad26a19a4",
    },
  ];
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="flex flex-col gap-8"
    >
      <div className={`w-full ${show && "opacity-0"}`}>
        <div className="relative flex text-start items-center pl-4 md:pl-8 w-full h-96 bg-[url('https://images.unsplash.com/photo-1594321610209-b0f463805378')] bg-center bg-no-repeat bg-cover object-center object-contain">
          <h1 className="text-[3rem] md:text-6.5xl text-start relative z-10 font-PlayfairDisplay text-white">
            Youth Church
          </h1>
          <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-t from-black to-transparent"></div>
        </div>
      </div>
      {/* Mission */}
      <div className="h-max p-3 grid place-items-center">
        <div className="relative flex flex-wrap items-center justify-center gap-4 mb-8">
          <h2 className="w-full text-center header-lvl-2 font-PlayfairDisplay">
            Our Mission
          </h2>
          <hr className="hr-line" />
        </div>
        <div
          className={`grid ${
            youthIntro?.length > 0 && "md:grid-cols-2 "
          } gap-4 w-10/12`}
        >
          <div className="grid place-items-center">
            <p
              className={`text-sm text-center ${
                youthIntro?.length > 0 && " md:text-left"
              } leading-[32px]`}
            >
              The members of the Church Missionary Society of St. Jude’s
              Anglican Church, Ebute-Metta, Lagos established St. Jude’s
              Anglican Church Shangisha/Magodo primarily through their
              evangelical fervour in March 1988. The Church held its first
              service on Sunday 13 March 1988 in an open space between houses
              number 7 and 9 Onibeju Street, Shangisha belonging to Messrs. J.O.
              Ogunfowoke and J.O. Adewunmi respectively, which about 64 people
              attended (mainly CMS Ebute-Metta members).
            </p>
          </div>
          <div
            id="youth-mini-home-gallery"
            className="hidden md:grid place-items-center"
          >
            {isLoading ? (
              <p>Loading</p>
            ) : (
              youthIntro?.length > 0 && <Collage images={youthIntro} />
            )}
          </div>
        </div>
      </div>
      {/* Who we are */}
      <div className="grid place-items-center mt-12">
        <div className="relative flex flex-wrap items-center justify-center gap-4 mb-8">
          <h2 className="w-full text-center capitalize header-lvl-2 font-PlayfairDisplay">
            Who we are
          </h2>
          <hr className="hr-line" />
        </div>
        <ol className="md:w-[400px] w-10/12 text-center flex flex-col gap-4">
          <li className="text-sm leading-[32px]">
            1. We are a group of unique and diverse individuals who have found
            common ground in the service vccv of the Lord
          </li>
          <li className="text-sm leading-[32px]">
            2. We hope to achieve Spiritual growth not just as individuals but
            in our community at large and to foster unity amongst other members
            of the church
          </li>
        </ol>
      </div>
      {/* Upcoming Events */}
      <div className="grid place-items-center mt-12 py-4">
        <div className="relative flex flex-wrap items-center justify-center gap-4 mb-8">
          <h2 className="w-full text-center capitalize header-lvl-2 font-PlayfairDisplay">
            Upcoming events
          </h2>
          <hr className="hr-line" />
        </div>
        <div className="w-10/12">
          <div className="text-black grid grid-cols-1 lg:grid-cols-2 gap-8">
            {newslist.map((news) => {
              return (
                <NewsCard
                  key={news.id}
                  title={news.title}
                  description={news.description}
                  image={news.image}
                />
              );
            })}
          </div>
        </div>
      </div>
      {/* Gallery */}
      <div className="grid place-items-center mt-12">
        <div className="relative flex flex-wrap items-center justify-center gap-4 mb-8">
          <h2 className="w-full text-center capitalize header-lvl-2 font-PlayfairDisplay">
            Gallery
          </h2>
          <hr className="hr-line" />
        </div>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          gallery?.length > 0 && (
            <div className="w-10/12 flex flex-col gap-5 mb-8">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 h-[200px] md:h-[400px]">
                <div
                  style={{ "--image-url": `url('${gallery[0]}')` }}
                  className={`bg-[image:var(--image-url)] bg-cover bg-center hidden md:block rounded-tl-3xl rounded-bl-lg rounded-r-lg shadow-lg`}
                ></div>
                <div
                  style={{ "--image-url": `url('${gallery[1]}')` }}
                  className="bg-[image:var(--image-url)] bg-cover bg-center md:col-span-2 rounded-tl-2xl rounded-bl-lg rounded-r-lg md:rounded-lg shadow-lg"
                ></div>
                <div
                  style={{ "--image-url": `url('${gallery[2]}')` }}
                  className="bg-[image:var(--image-url)] bg-cover bg-center rounded-tr-2xl md:rounded-tr-3xl rounded-br-lg rounded-l-lg shadow-lg"
                ></div>
              </div>
              <div className="grid grid-cols-2 gap-4 h-[200px] md:h-[400px]">
                <div
                  style={{ "--image-url": `url('${gallery[3]}')` }}
                  className="bg-[image:var(--image-url)] bg-cover bg-center rounded-bl-2xl md:rounded-bl-3xl rounded-tl-lg rounded-r-lg shadow-lg"
                ></div>
                <div
                  style={{ "--image-url": `url('${gallery[4]}')` }}
                  className="bg-[image:var(--image-url)] bg-cover bg-center rounded-br-2xl md:rounded-br-3xl rounded-tr-lg rounded-l-lg shadow-lg"
                ></div>
              </div>
            </div>
          )
        )}

        <Link
          to="/gallery"
          onClick={handleClick}
          className="bg-transparent border border-[#EC5929] hover:bg-[#EC5929] hover:text-white text-[#EC5929] w-max px-4 py-2 rounded-md text-sm font-medium hover:shadow-xl"
        >
          View More
        </Link>
      </div>
      {/* <Footer /> */}
    </motion.div>
  );
}

export default Youth;
