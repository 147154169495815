import React, { useEffect, useState } from "react";
import { allPromiseResults } from "../firebase-results/firebase-results";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { motion } from "framer-motion";
import Footer from "./footer";

function About() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="grid place-items-center"
    >
      <div className="flex flex-wrap items-center mt-8 sm:mt-4 justify-center gap-4 md:mb-0 mb-3">
        <h2 className="w-full text-center header-lvl-2 font-PlayfairDisplay">
          About
        </h2>
        <hr className="hr-line" />
      </div>
      <div className="grid place-items-center p-8 max-w-5xl">
        <p className="grid gap-[1.4rem]">
          <span className="leading-[32px]">
            The members of the Church Missionary Society of St. Jude’s Anglican
            Church, Ebute-Metta, Lagos established St. Jude’s Anglican Church
            Shangisha/Magodo primarily through their evangelical fervour in
            March 1988. The Church held its first service on Sunday 13 March
            1988 in an open space between houses number 7 and 9 Onibeju Street,
            Shangisha belonging to Messrs. J.O. Ogunfowoke and J.O. Adewunmi
            respectively, which about 64 people attended (mainly CMS Ebute-Metta
            members). In due course, the nascent Church moved to another open
            space by the house of a member of the Church - Chief. T. Odubanjo on
            Sunday 16 October 1994. It was on this site that the first structure
            of the Church was erected – a temporary wooden shack. One can safely
            assume that the name “St. Jude’s Anglican Church Shangisha” was a
            mark of the natural affiliation with the parent church, St. Jude’s
            Anglican Church, Ebute-Metta. The administration of the church was
            entrusted to visiting priests and ordinands from St. Jude’s
            Ebute-Metta such as Reverends I. Owoyele, Olaoletan, Saka, George,
            Femi Fasina and Alfred Adegoye. These clergymen were at various
            times assisted by members of the laity such as Mr. J.O. Ogunfowoke.
            As a mark of the growth and development of the Church aided by the
            quirky twist of fate and the benevolence of yet the parent church,
            St. Jude’s Anglican Church, Ebute-Metta, the Church acquired the
            present site in Magodo Phase, as a donation in July 1996. The land
            actually belonged to an Anglican Church in Ilaje, as a compensation
            for the acquisition of its land for part of the construction of the
            Third Mainland Bridge. As the Ilaje people showed scant interest in
            abandoning their riverine lifestyle by taking possession of the
            compensated land in Magodo, the lot of occupying the vacant land
            fell on the burgeoning church in Shangisha. The parent church, being
            the archdeaconry headquarters for the Ilaje church, transferred the
            Magodo land to St. Jude’s Anglican Church Shangisha on Sunday 21
            July 1996. The movement to Magodo land eventually occurred on Sunday
            2 March 1997 when the first service took place.
          </span>
          <span className="leading-[32px]">
            The movement to Magodo coincided with the tenure of Reverend Philip
            Bolude Oyebolu (as he then was) who resumed office on Friday 9
            January 1998. He conducted his first service on Sunday 11 January
            1998 in a structure measuring only about 55 X 35 feet, which had
            neither windows nor doors! The tenure of Oyebolu was epochal. The
            first structure was vastly upgraded and a Sunday School building was
            erected. He went on to draw up a three-phase development plan
            wherein phase 1 was the construction of a modern church hall (to be
            used for worship, initially); phase 2 – the construction of a
            two-flat vicarage; and phase 3 – the construction of a new
            millennium church, large enough to accommodate between 1,500 – 2,000
            worshippers. On Thursday 9 September 1999 (9/9/99), the foundation
            of the church hall (phase 1) was laid by His Grace, the Most
            Reverend Abiodun Adetiloye, the Bishop of Lagos. The Right Reverend
            Peter Awelewa Adebiyi, the Bishop of the newly created Diocese of
            Lagos-West, dedicated the building on Saturday 7 June 2001. Bishop
            Adebiyi laid the foundation of the vicarage on Sunday 22 June 2002
            under the tenure of Reverend Oluwagbemiga Onayemi (as he then was),
            who served in the church as the vicar from July 2001 to January
            2006. He was the driving force behind the construction of the
            vicarage, which was up to the roofing level before his transfer. It
            was during the tenure of his successor – Reverend Gbenga Fagbemi (as
            he then was) that the vicarage was completed and dedicated as a
            turnkey project on Friday 4 July 2008. Bishop Adebiyi also conducted
            the dedication of the vicarage.
          </span>
          <span className="leading-[32px]">
            Much groundwork for the construction of the millennium church was
            done during the tenure of previous priests, especially during the
            time of Reverend Canon M.A.O. Abraham- Odumuyiwa, who incidentally
            was the first resident vicar of the church. There was tremendous
            delay in getting started on the construction of the millennium
            church, mostly associated with the bureaucracy of getting the
            requisite governmental papers. The Right Reverend James Olusola
            Odedeji – the Bishop Coadjutor of the Diocese of Lagos- West laid
            the foundation of the much-awaited millennium church on Saturday 16
            March 2013. Ironically, the laying of the foundation happened to be
            the first episcopal assignment of the new bishop. Reverend Babatunde
            Adegoroye (as he then was), the vicar of the church during the
            laying of the foundation promised the Bishop Coadjutor that the
            construction would be completed within two years. Reverend Adegoroye
            was full of zeal and zest in getting the construction of the
            millennium church underway. He initiated the Nehemiah Family to
            raise funds for the construction of the building. There was a delay
            of almost 10 months in commencing the construction of the millennium
            church, which eventually happened towards the end of December 2013,
            as one of the very last deeds of the Reverend Canon Babatunde
            Adegoroye before his transfer in January 2014.
          </span>
          <span className="leading-[32px]">
            There was a change in baton in the administration of the church,
            which witnessed the posting of Venerable Abiade Adeniji to the
            church as the vicar on the Wednesday 16 January 2014. One of the
            immediate tasks the new vicar faced was getting the construction
            work fully off the ground and complete within one year – partly in
            keeping with the public declaration to the Bishop Coadjutor (now
            substantive Bishop) of accomplishing the construction within 2 years
            of the laying of the foundation. The millennium church building is
            an ultra-modern edifice estimated at the cost of N100, 000,000.00
            (One Hundred Million Naira) only. It is to be the centre of Magodo
            community, apart from being a worship and community centre for the
            propagation of Christ to the world. It is noteworthy that the
            construction of the millennium church building, as the name implies,
            is about 14 years behind schedule but God’s time is the best – which
            is now.
          </span>
        </p>
      </div>
      {/* <Footer /> */}
    </motion.div>
  );
}

export default About;
