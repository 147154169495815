import React, { useContext, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import LocationContext from "../context/location";
import NavBtn from "./NavBtn";
import { motion } from "framer-motion";
import logo from "../images/ang-logo-edited.png";

function Navbar() {
  const [menu, showMenu] = useState(false);

  const navref = useRef();
  const scrollTo = useContext(LocationContext);
  const handle = () => {
    showMenu(!menu);
    handleClick();
  };
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  const toNews = () => {
    setTimeout(async () => {
      scrollTo.news_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  const toService = () => {
    setTimeout(async () => {
      scrollTo.service_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  const toPrayer = () => {
    setTimeout(async () => {
      scrollTo.prayer_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  const toSunday = () => {
    setTimeout(async () => {
      scrollTo.sunday_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  const toClergy = () => {
    setTimeout(async () => {
      scrollTo.clergy_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  const toHistory = () => {
    setTimeout(async () => {
      scrollTo.history_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  const toAnthem = () => {
    setTimeout(async () => {
      scrollTo.anthem_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  const toResources = () => {
    setTimeout(async () => {
      scrollTo.resources_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  const toGallery = () => {
    setTimeout(async () => {
      scrollTo.gallery_sect_Location();
    }, 100);
    handle();
    handleClick();
  };
  return (
    <div className="sticky top-0 z-50">
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 0.5, type: "tween" }}
        className="md:bg-[#333333] bg-[#F1F1F1] px-6 lg:px-40 py-3 flex justify-between items-center "
      >
        
        <div className="items-center justify-center hidden w-full gap-6 text-white md:flex">
          <div className="flex items-center gap-1 cursor-pointer hover:-translate-y-1">
            {/* <span className="text-lg material-symbols-rounded">mail</span> */}
            <span className="sr-only">Email</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>

            <a href="mailto:st.judes.magodo@gmail.com" className="text-xs font-medium">
              st.judes.magodo@gmail.com
            </a>
          </div>
          <div className="flex items-center gap-1 cursor-pointer hover:-translate-y-1">
            {/* <span className="text-lg material-symbols-rounded">phone</span> */}
            <span className="sr-only">Telephone</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>
            <a href="tel:+2349024503286" className="text-xs font-medium">
              +234 902 450 3286
            </a>
          </div>
          <NavLink
            to="/"
            onClick={toService}
            className="px-10 py-2 text-xs border-white rounded-lg cursor-pointer border-[1px] hover:-translate-y-1"
          >
            Join Us
          </NavLink>
        </div>
        <div className="block md:hidden">
          <span
            className="text-black cursor-pointer material-symbols-rounded"
            onClick={handle}
          >
            menu
          </span>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 0.5, type: "tween" }}
        className="bg-[#F1F1F1] backdrop-blur-md px-14 py-4 hidden md:flex justify-around items-center text-xs font-medium uppercase"
      >
        <NavLink
          to="/"
          onClick={handleClick}
          className="flex items-center text-sm font-bold text-primary md:text-white"
        >
          <img className="w-[5.168rem] h-[3rem] object-contain" src={logo} alt="" />
        </NavLink>
        <div className="cursor-pointer">
          <Link to="/" onClick={handleClick}>
            Home
          </Link>
        </div>
        <div className="flex items-center cursor-pointer">
          <Link to="/about" onClick={handleClick}>
            About
          </Link>
        </div>
        <div className="flex items-center cursor-pointer">
          <Link to="/" onClick={toGallery}>
            Gallery
          </Link>
        </div>
        {/* <div className="cursor-pointer">
          <NavLink to="/" onClick={toService}>
            Sermons
          </NavLink>
        </div> */}
        {/* <div className="cursor-pointer">
          <Link to="/youth" onClick={handleClick}>
            Youth
          </Link>
        </div> */}
        <div className="cursor-pointer">
          <NavLink to="/" onClick={toNews}>
            News
          </NavLink>
        </div>
        <div className="flex items-center cursor-pointer">
          <NavLink to="/" onClick={toSunday}>
            Sunday Service
          </NavLink>
          {/* <span className="text-lg material-symbols-rounded">expand_more</span> */}
        </div>
        <div className="flex items-center cursor-pointer">
          <NavLink to="/clergy" onClick={toClergy}>
            Clergy
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink to="/" onClick={toHistory}>
            History
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink to="/" onClick={toAnthem}>
            Anthem
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink to="/" onClick={toResources}>
            Resources
          </NavLink>
        </div>
        <div className="cursor-pointer">
          <NavLink to="/" onClick={toPrayer}>
            Prayer Request
          </NavLink>
        </div>
      </motion.div>
      {menu && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.6, type: "tween" }}
          className="bg-[#000000] z-[50] bg-opacity-60 backdrop-blur-md fixed md:hidden overflow-y-scroll flex justify-end top-0 right-0 w-full"
          ref={navref}
        >
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ x: 200 }}
            transition={{ ease: "easeIn", duration: 0.3 }}
            className="bg-[#F1F1F1] relative z-[60] text-white p-4 w-[70%] sm:w-[40%] flex flex-col"
          >
            <div className="flex items-center justify-end">
              <span
                className="material-symbols-rounded p-0.5 bg-[#EC5929] cursor-pointer text-white rounded-md"
                onClick={handle}
              >
                close
              </span>
            </div>
            <div className="flex flex-col justify-between flex-1">
              <div className="flex flex-col gap-4 mt-8">
                <NavBtn title="Home" link="/" handleClick={handle} />
                <NavBtn title="About" link="/" handleClick={handle} />
                
          <NavBtn title="Gallery" link="/" handleClick={toGallery} />
                {/* <NavBtn title="Sermons" link="/" handleClick={toService} /> */}
                {/* <NavBtn title="Youth" link="/youth" handleClick={handle} /> */}
                <NavBtn title="News" link="/" handleClick={toNews} />
                <NavBtn
                  title="Sunday Service"
                  link="/"
                  handleClick={toService}
                />
                <NavBtn title="Clergy" link="/clergy" />
                <NavBtn title="History" link="/" handleClick={toHistory} />
                <NavBtn title="Anthem" link="/" handleClick={toAnthem} />
                <NavBtn title="Resources" link="/" handleClick={toResources} />
                <NavBtn
                  title="Prayer Request"
                  link="/"
                  handleClick={toPrayer}
                />
              </div>
            </div>
          </motion.div>
          <div
            className="absolute w-full h-full p-2 bg-transparent"
            onClick={handle}
          ></div>
        </motion.div>
      )}
    </div>
  );
}

export default Navbar;
