import React from "react";

function Collage({images}) {
  return (
    <div className="flex gap-4 items-center order-first md:order-last justify-center">
      <img
        src={images[0]}
        alt=""
        className="h-[100px] md:w-[136px] w-3/4 rounded-md"
      />
      <div className="hidden lg:flex flex-col gap-4">
        <img
          src={images[1]}
          alt=""
          className="h-[100px] w-[136px] rounded-md"
        />
        <img
          src={images[2]}
          alt=""
          className="h-[100px] w-[136px] rounded-md"
        />
      </div>
      <img
        src={images[3]}
        alt=""
        className="h-[100px] w-[136px] rounded-md hidden md:block"
      />
    </div>
  );
}

export default Collage;
