import React from "react";

function NewsCard({ title, description, image }) {
  return (
    <div className="flex flex-col md:flex-row items-center bg-[#F8F8F8] p-3 rounded-xl shadow-xl gap-3 h-max ">
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className={`h-[100px] w-full rounded-md md:w-[140px] bg-cover bg-center bg-red-700`}
      ></div>
      <div className="text-left flex flex-col w-full justify-center ">
        <p className="text-lg mb-1 heading font-semibold">{title}</p>
        <p className="text-xs leading-[1.3rem]">{description}</p>
      </div>
    </div>
  );
}

// md:h-[140px] min-h-[140px]
export default NewsCard;
