import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { motion } from "framer-motion";

function YouthGallery({imgs}) {
  const [gallery, setGallery] = useState([]);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([{}, { src: "" }]);

  useEffect(() => {
    const setGalleryImages = () => {
      setGallery(imgs);
    };

    const newArr = gallery.map((img, index) => {
      return { ...images[index], src: img };
    });
    setImages(newArr);
    setGalleryImages();
  }, [gallery]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="w-full grid place-items-center"
    >
      <div className="flex flex-wrap items-center mt-4 justify-center gap-4 md:mb-0 mb-8">
        <h2 className="w-full text-center header-lvl-2 font-PlayfairDisplay">
          Gallery
        </h2>
        <hr className="hr-line" />
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 w-10/12 mt-8 py-4">
        {images.map((image, id) => {
          return (
            <div className="relative cursor-pointer gall overflow-hidden rounded-lg">
              <div
                key={id}
                style={{ "--image-url": `url('${image.src}')` }}
                onClick={() => setOpen(true)}
                className={`bg-[image:var(--image-url)] imgx cursor-pointer hover:scale-[1.01] bg-cover bg-top sm:bg-center rounded-lg shadow-lg h-[200px] md:h-[400px]`}
              ></div>
              <div
                className="bg-black/20 bgi hidden p-2 absolute top-0 rounded-lg w-full h-full"
                onClick={() => setOpen(true)}
              ></div>
            </div>
          );
        })}
      </div>
      <Lightbox
        plugins={[Counter]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        open={open}
        close={() => setOpen(false)}
        slides={images}
      />
    </motion.div>
  );
}

export default YouthGallery;
