import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function Prayerform() {
  const [loading, setLoading] = useState(false);
  useEffect(() => emailjs.init("2oj97zMvRGt-ZzrsY"), []);

  const form = useRef();
  const emailRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const detailsRef = useRef();

  const [enteredData, setData] = useState({
    name: "",
    email: "",
    phone: "",
    details: "",
  });
  function handleInput(key, value) {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }
  function clearForm(e) {
    setData({
      name: "",
      email: "",
      phone: "",
      details: "",
    });
  }
  async function handleSubmit(event) {
    event.preventDefault();
    const serviceId = "service_9px4eqc";
    const templateId = "template_nwi8svg";
    clearForm();
    alert("Email sending...");
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        from_name: nameRef.current.value,
        from_email: emailRef.current.value,
        phone_no: phoneRef.current.value,
        details: detailsRef.current.value,
      });
      alert("Email successfully sent! We will get back to you shortly.");
    } catch (error) {
      alert("Error: " + error);
        } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full text-black">
      <p className="font-bold text-xl mb-4">Send your prayer request</p>
      <form className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Name</label>
          <input
            className="text-sm font-medium p-3 rounded-sm outline-0 border border-[#EAEAEA]"
            placeholder="Name"
            name="from_name"
            ref={nameRef}
            onChange={(event) => handleInput("name", event.target.value)}
            value={enteredData.name}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Email</label>
          <input
            className="text-sm font-medium p-3 rounded-sm outline-0 border border-[#EAEAEA]"
            placeholder="Email"
            type="email"
            name="from_email"
            ref={emailRef}
            onChange={(event) => handleInput("email", event.target.value)}
            value={enteredData.email}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Phone Number</label>
          <input
            className="text-sm font-medium p-3 rounded-sm outline-0 border border-[#EAEAEA]"
            placeholder="Phone Number"
            type=""
            ref={phoneRef}
            name="phone_no"
            onChange={(event) => handleInput("phone", event.target.value)}
            value={enteredData.phone}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Details</label>
          <textarea
            className="text-sm font-medium p-3 rounded-sm outline-0 border border-[#EAEAEA] resize-none"
            placeholder="Type here..."
            rows={5}
            name="message"
            ref={detailsRef}
            onChange={(event) => handleInput("details", event.target.value)}
            value={enteredData.details}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-4 text-sm tracking-widest">
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-[#624736] hover:bg-white hover:text-[#624736] border border-[#624736] px-4 py-2 md:py-4 rounded-md text-white shadow-lg"
          >
            Submit
          </button>
          <button
            onClick={clearForm}
            className="border border-[#624736] px-4 py-2 md:py-4 rounded-md hover:shadow-lg"
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
}

export default Prayerform;
