import React from "react";
import { Link } from "react-router-dom";

function NavBtn({ title, link, handleClick }) {
  return (
    <Link
      onClick={handleClick}
      to={link}
      className="bg-transparent capitalize cursor-pointer border border-[#EC5929] hover:bg-[#EC5929] hover:text-white text-[#EC5929] w-full grid place-items-center px-4 py-2 rounded-md text-sm font-medium hover:shadow-xl"
    >
      {title}
      {/* <Link to={link} className="capitalize"></Link> */}
    </Link>
  );
}

export default NavBtn;
