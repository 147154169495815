import React, { Fragment } from "react";

function Resources() {
  return (
    <div
      id="resources"
      className="h-max p-3 min-h-[410px] grid place-items-center mb-8"
    >
      <div className="flex flex-col mt-32 items-center gap-[1.5rem] text-black w-11/12 md:w-9/12">
        <div className="grid gap-2 mb-4 place-items-center">
          <p className="text-3xl text-center text-black heading">Resources</p>
          <div className="bg-[#EC5929] h-1 w-10 rounded-md"></div>
        </div>
        <div className="grid grid-cols-none gap-8 lg:grid-cols-6 ">
          <div className="flex flex-col items-center justify-center order-first col-span-3 gap-4 text-center md:items-start md:text-left">
            <span className="text-lg font-semibold uppercase">COLLECT</span>
            <p className="text-sm leading-[32px]">
              Almighty God, You have broken the tyranny of sin have sent the
              Spirit of Your Son into our hearts whereby we call You Father.
              Give us grace to dedicate our freedom to Your servce, that all
              mankind may be brought to the glorious liberty of the sons of God,
              thorugh Jesus Christ our Lord. Amen
            </p>
          </div>
          <div className="flex flex-col items-center justify-center order-last col-span-3 gap-4">
            
            <Resource
              buttonText="Church of Nigeria Hymnal"
              app={true}
              android={true}
            />
            <Resource
              buttonText="Bible Study Outline"
              app={true}
              android={true} ios={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;

const Resource = ({ buttonText, app, ios, android }) => {
  return (
    <div className="flex w-full justify-between items-center bg-[url('https://images.unsplash.com/photo-1594321570326-9e5ad26a19a4)] md:bg-[#F8F8F8] md:bg-none md:p-3 rounded-xl shadow-xl gap-3">
      <img
        src="https://images.unsplash.com/photo-1594321570326-9e5ad26a19a4"
        alt=""
        className="hidden md:block h-[100px] w-[100px] rounded-xl shadow"
      />
      <div className="relative flex flex-col justify-center w-full p-8 text-left md:p-0 ">
        <div className="absolute top-0 left-0 block w-full h-full bg-center bg-cover bg-res md:hidden rounded-xl"></div>
        {/* <div className="relative z-10 flex justify-end">
                  <p className="bg-[#045A19] w-8 h-8 rounded-full text-[10px] font-bold uppercase text-white grid place-items-center">
                    Free
                  </p>
                </div> */}
        <p className="relative z-10 mb-4 text-xl font-semibold text-white md:text-black heading">
          {buttonText}
        </p>
        {app ? (
          <div className="flex items-center gap-4">
            {android && (
              <a
                href="https://play.google.com/web/store/apps/details?id=com.egbadiocese.biblestudy2018&pli=1&raii=com.egbadiocese.biblestudy2018&raboi=CAE%3D&rasi=2673671643350114169&rapt=AEjHL4Nw2sfUHj8yWbr8mJgK9KZvJ10FD-jjR5tcj94-pUA0okQN7EimY9NvCSn9jDP6YTHfMdsVnS01uH5EmjcqOJGt7LoXjLpmo3DxGpNVVH6GALtK6XoLSTJgF9eHUko3R2LGCfuH"
                target="_blank"
                className="relative z-10 text-white bg-[#EC5929] hover:bg-[#EC5929]/90 w-full md:w-3/6 flex gap-2 items-center justify-center px-3 py-2 shadow-lg rounded-lg text-sm font-medium cursor-pointer hover:shadow "
              >
                <span>Open</span>
                <i class="fa-brands fa-google-play"></i>
              </a>
            )}
            {ios && (
              <a
                href="https://play.google.com/web/store/apps/details?id=com.egbadiocese.biblestudy2018&pli=1&raii=com.egbadiocese.biblestudy2018&raboi=CAE%3D&rasi=2673671643350114169&rapt=AEjHL4Nw2sfUHj8yWbr8mJgK9KZvJ10FD-jjR5tcj94-pUA0okQN7EimY9NvCSn9jDP6YTHfMdsVnS01uH5EmjcqOJGt7LoXjLpmo3DxGpNVVH6GALtK6XoLSTJgF9eHUko3R2LGCfuH"
                target="_blank"
                className="relative z-10 text-white bg-[#EC5929] hover:bg-[#EC5929]/90 w-full md:w-3/6 flex gap-2 items-center justify-center px-3 py-2 shadow-lg rounded-lg text-sm font-medium cursor-pointer hover:shadow "
              >
                <span>Open</span>
                <i class="fa-brands fa-app-store-ios"></i>
              </a>
            )}
          </div>
        ) : (
          <a
            href="/"
            target="_blank"
            className="relative z-10 text-white bg-[#EC5929] hover:bg-[#EC5929]/90 w-full md:w-3/6 flex gap-2 items-center justify-center px-3 py-2 shadow-lg rounded-lg text-sm font-medium cursor-pointer hover:shadow "
          >
            <span>View</span>
          </a>
        )}
      </div>
    </div>
  );
};
