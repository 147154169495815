import React from "react";

function Footer() {
  return (
    <div className="h-max min-h-[410px] w-full mt-40 flex flex-col justify-between bg-[#F8F8F8]">
      <p className="px-4 pt-4 text-2xl font-medium text-black md:text-5xl heading">
        St Jude's Anglican Chruch Magodo
      </p>
      <div className="grid flex-1 md:grid-cols-3">
        <div className="p-4 md:col-span-2">
          <div className="sm:min-h-[220px] text-black border-t border-b py-4 border-black flex flex-col sm:gap-4">
            <span className="mb-3 text-lg font-semibold sm:mb-0">
              Account Information
            </span>
            <p className="text-sm sm:text-base">
              Account Name :{" "}
              <span className="font-semibold">
                St Jude's Anglican Church, Magodo
              </span>
            </p>
            <p className="mt-2 text-sm sm:text-base">
              Account Number : <span className="font-semibold">1011294851</span>
            </p>
            <p className="mt-2 text-sm sm:text-base">
              Bank Name : <span className="font-semibold">Zenith</span>
            </p>
            <p className="mt-2 text-xs font-medium">
              *Please send an email to{" "}
              <a
                href="mailto:st.judes.magodo@gmail.com"
                className="font-semibold"
              >
                st.judes.magodo@gmail.com
              </a>{" "}
              to receive receipt
            </p>
          </div>
        </div>
        <div className="px-4 md:py-4">
          <div className="sm:min-h-[220px] text-black border-t-0 md:border-t py-0 md:py-4 border-black flex flex-col sm:gap-3">
            <span className="mb-3 text-lg font-semibold sm:mb-0">Location</span>
            <a
              href="https://maps.app.goo.gl/4AfBdURQCDXinCA58"
              target="_blank"
              rel="noreferrer"
              className="text-sm hover:underline"
            >
              17 Idowu Akindele Crescent, Magodo GRA. Off Alh. Bashiru Shittu.
            </a>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.189119928426!2d3.3776881750411007!3d6.623415193370829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b930834729b75%3A0xa62fc1e03e8427fc!2sSt%20Jude&#39;s%20Anglican%20Church!5e0!3m2!1sen!2sng!4v1721594256618!5m2!1sen!2sng"
              width="400"
              height="300"
              className="!w-full rounded-lg sm:rounded-2xl ring-1 mt-4 sm:mt-2 ring-[#624736]/40 !h-[200px]"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="flex flex-col mt-4 border-t border-black sm:mt-0 sm:gap-3">
              <span className="mt-3 text-lg font-semibold">Contact Us</span>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:st.judes.magodo@gmail.com"
                className="hidden mt-2 text-sm underline cursor-pointer sm:block sm:mt-0"
              >
                st.judes.magodo@gmail.com
              </a>
              <div className="flex gap-4 mt-3 mb-5 sm:mt-0 sm:mb-0">
                <a
                  href="mailto:st.judes.magodo@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="grid social-links sm:hidden place-items-center hover:bg-gradient-to-tr from-teal-600 to-rose-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-5 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/stjudesmagodo/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-links hover:bg-[#4267B2]"
                >
                  {/* <img src={require("../images/fb.png")} alt="" /> */}
                  <i class="fa-brands fa-facebook-f text-white text-lg"></i>
                </a>
                <a
                  href="https://www.instagram.com/stjudesanglicanmagodo?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  target="_blank"
                  rel="noreferrer"
                  className="social-links hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"
                >
                  {/* <img src={require("../images/ig.png")} alt="" /> */}
                  <i class="fa-brands fa-instagram text-white text-lg"></i>
                </a>
                <a
                  href="https://x.com/stjudesmagodo?lang=en"
                  target="_blank"
                  rel="noreferrer"
                  className="social-links"
                >
                  {/* <img src={require("../images/x.png")} alt="" /> */}
                  <i class="fa-brands fa-x-twitter text-white text-lg"></i>
                </a>
                <a
                  href="https://www.youtube.com/@stjudesmagodo"
                  target="_blank"
                  rel="noreferrer"
                  className="social-links group"
                >
                  <i class="fa-brands fa-youtube group-hover:text-[#ff0000] text-white text-lg"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center p-4 text-xs font-medium text-black">
        © {new Date().getFullYear()} St Jude's Anglican Church Magodo
      </div>
    </div>
  );
}

export default Footer;
