import React from "react";
import Prayerform from "../components/prayerform";

function Sendtabs() {
  return (
    <div
      className="h-max p-6 min-h-[430px] flex gap-6 flex-col items-center mb-8"
      id="prayer"
    >
      <div className="tabs grid gap-4 mt-32 border-2 md:w-3/12 border-[#EC5929] overflow-hidden rounded-md">
        <span className="text-xs text-center md:text-sm font-medium px-4 py-2 shadow-none bg-[#EC5929] text-white disabled:shadow-lg">
          Prayer request
          {/* w-10/12 md:w-5/12  */}
        </span>
        {/* <button className="bg-transparent text-xs md:text-sm font-medium text-black p-2 md:p-4 rounded-full cursor-pointer shadow-none disabled:bg-[#EC5929] disabled:text-white disabled:shadow-lg">
          Send message
        </button> */}
      </div>
      <div className="content w-full md:w-8/12 border-2 bg-white border-[#DEDEDE] shadow-lg px-4 py-4 md:px-8 md:py-10 rounded-2xl">
        <Prayerform />
      </div>
    </div>
  );
}

export default Sendtabs;
