// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import axios from 'axios';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfigPromise = axios.get('https://stjudes-backend-sunkanmiis-projects.vercel.app/', { withCredentials: true })
.then((res) => res.data)
.catch(err => {
    console.log(err)
})


export { firebaseConfigPromise };

// Initialize Firebase 