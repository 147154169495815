import React from "react";

function Sunday() {
  return (
    <div
      id="sunday"
      className="bg-[url('https://images.unsplash.com/photo-1598715931422-f09324c77c3e')] mt-32 bg-cover text-white h-max p-6 min-h-[400px] relative grid gap-3 place-items-center mb-8"
    >
      <div className="bg-black/60 absolute top-0 left-0 w-full h-full"></div>
      <div className="w-11/12 md:w-9/12 relative z-30 grid gap-8 md:gap-[4rem]">
        <div className="flex flex-col gap-4">
          <p className="text-3xl heading text-center">Sunday Service</p>
          <div className="h-[1px] bg-white w-full"></div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="grid lg:grid-cols-3 text-center gap-8 w-4/5">
            <div className="flex flex-col gap-3">
              <span
                className="program text-sm font-bold"
                style={{ fontFamily: "Playfair Display" }}
              >
                SUNDAY SERVICE
              </span>
              <p className="text-2xl md:text-5xl font-semibold">8:00 AM</p>
            </div>
            <div className="flex flex-col gap-3">
              <span className="program text-sm font-bold">
                CHILDREN SERVICE
              </span>
              <p className="text-2xl md:text-5xl font-semibold">8:00 AM</p>
            </div>
            <div className="flex flex-col gap-3">
              <span className="program text-sm font-bold">YOUTH SERVICE</span>
              <p className="text-2xl md:text-5xl font-semibold">8:00 AM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sunday;
