import "./App.css";
import Navbar from "./components/Nav";
import Home from "../src/views/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Youth from "./views/youth";
import YouthGallery from "./views/gallery";
import About from "./sections/about";
import { firebaseConfigPromise } from "./firebase";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import Footer from "./sections/footer";
import Clergy from "./views/clergy";

function App() {
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [homeBackground, setHomeBackground] = useState([]);
  const [historyImgs, setHistoryImgs] = useState([]);
  const [serviceTime, setServiceTime] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [youthIntro, setYouthIntro] = useState([]);
  const [priestsImgs, setPriests] = useState([]);

  // Define states for other types of data here

  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const firebaseConfig = await firebaseConfigPromise
          .then((res) => {
            setDataFetched(true);
            return res;
          })
          .catch((err) => {
            // console.log(err);
          });
        initializeApp(firebaseConfig); // Initialize Firebase app
        const db = getFirestore(); // Get Firestore instance
        const storage = getStorage(); // Get Storage instance

        // Fetch data for each type and set state
        await fetchImageData(storage, "gallery/");
        await fetchImageData(storage, "home-background/");
        await fetchImageData(storage, "history/");
        await fetchImageData(storage, "service-time/");
        await fetchImageData(storage, "upcoming-events/");
        await fetchImageData(storage, "youth-intro/");
        await fetchImageData(storage, "priests/");

        // Set loading to false after all data is fetched
        setLoading(false);
        setDataFetched(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [dataFetched]);

  const fetchImageData = async (storage, path) => {
    try {
      const storageRef = ref(storage, path);
      const result = await listAll(storageRef);
      const urlPromises = result.items.map((imageRef) =>
        getDownloadURL(imageRef)
      );
      const urls = await Promise.all(urlPromises);

      // Set state based on path
      switch (path) {
        case "gallery/":
          setGalleryFiles(urls);
          break;
        case "home-background/":
          setHomeBackground(urls);
          break;
        case "history/":
          setHistoryImgs(urls);
          break;
        case "service-time/":
          setServiceTime(urls);
          break;
        case "upcoming-events/":
          setUpcomingEvents(urls);
          break;
        case "youth-intro/":
          setYouthIntro(urls);
          break;
        case "priests/":
          setPriests(urls);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  return (
    <BrowserRouter>
      <div className="bg-white App">
        <Navbar />
        <Routes>
          <Route
            index
            element={
              <Home
                homeBackground={homeBackground}
                serviceTime={serviceTime}
                priestsImgs={priestsImgs}
                loading={loading}
                historyImgs={historyImgs}
              />
            }
          />

          <Route
            path="youth"
            element={<Youth youthIntro={youthIntro} gallery={galleryFiles} />}
          />

          <Route
            path="gallery"
            element={<YouthGallery imgs={galleryFiles} />}
          />
          <Route path="about" element={<About />} />
          <Route path="clergy" element={<Clergy />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
