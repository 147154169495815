import { createContext } from "react";

const LocationContext = createContext({
  news_sect_Location: () => {
    const news_sect = document.getElementById("news");
    news_sect?.scrollIntoView({ behavior: "smooth" });
  },
  service_sect_Location: () => {
    const service_sect = document.getElementById("service");
    service_sect?.scrollIntoView({ behavior: "smooth" });
  },
  prayer_sect_Location: () => {
    const prayer_sect = document.getElementById("prayer");
    prayer_sect?.scrollIntoView({ behavior: "smooth" });
  },
  sunday_sect_Location: () => {
    const sunday_sect = document.getElementById("sunday");
    sunday_sect?.scrollIntoView({ behavior: "smooth" });
  },
  clergy_sect_Location: () => {
    const clergy_sect = document.getElementById("clergy");
    clergy_sect?.scrollIntoView({ behavior: "smooth" });
  },
  history_sect_Location: () => {
    const history_sect = document.getElementById("history");
    history_sect?.scrollIntoView({ behavior: "smooth" });
  },
  anthem_sect_Location: () => {
    const anthem_sect = document.getElementById("anthem");
    anthem_sect?.scrollIntoView({ behavior: "smooth" });
  },
  resources_sect_Location: () => {
    const resources_sect = document.getElementById("resources");
    resources_sect?.scrollIntoView({ behavior: "smooth" });
  },
  gallery_sect_Location: () => {
    const gallery_sect = document.getElementById("gallery");
    gallery_sect?.scrollIntoView({ behavior: "smooth" });
  }
});

export default LocationContext;
