import React from "react";

function HomeClergy({ priestsImgs }) {
  return (
    <div
      id="clergy"
      className="bg-white text-white h-max p-6 min-h-[430px] grid gap-3 place-items-center mb-8"
    >
      <div className="grid w-11/12 gap-3 mt-32 place-items-center md:w-9/12">
        <div className="grid w-3/5 gap-2">
          <p className="text-3xl text-center text-black heading">Clergy</p>
        </div>
        <div className="pt-10 border-t border-[#EC5929] grid sm:grid-cols-2 gap-6 w-full">
          <div className="clergy sm:col-span-2">
            <img
              src={priestsImgs.length > 0 ? priestsImgs[1] : ""}
              alt="The Rt Rev. James Olusola Adedeji"
              className="h-[150px] w-[150px] rounded-lg shadow object-contain"
            />
            <div className="text-center">
              <p className="heading text-[#EC5929] text-lg">
                Rt Rev. James Olusola Adedeji
              </p>
              <p className="text-xs font-medium tracking-wide text-neutral-700">
                Bishop of the Diocese (Lagos West)
              </p>
            </div>
          </div>
          <div className="clergy">
            <img
              src={priestsImgs.length > 0 ? priestsImgs[0] : ""}
              alt="The Ven. Sunday"
              className="h-[150px] w-[150px] rounded-lg shadow object-cover object-top"
            />
            <div className="text-center">
              <p className="heading text-[#EC5929] text-lg">
                Ven. Sunday Folorunso Oginni
              </p>
              <p className="text-xs font-medium tracking-wide text-neutral-700">
                Administrator, Ikosi Archdeaconry
              </p>
            </div>
          </div>
          <div className="clergy">
            <img
              src={priestsImgs.length > 0 ? priestsImgs[4] : ""}
              alt="Ven. Joseph Akinyele Omoyajowo"
              className="h-[150px] w-[150px] rounded-lg shadow object-cover"
            />
            <div className="text-center">
              <p className="heading text-[#EC5929] text-lg">
                Ven. Joseph Akinyele Omoyajowo{" "}
              </p>
              <p className="text-xs font-medium tracking-wide text-neutral-700">
                Vicar
              </p>
            </div>
          </div>
          <div className="clergy">
            <img
              src={priestsImgs.length > 0 ? priestsImgs[3] : ""}
              alt="Rev. Daniel Demola Fajuyagbe"
              className="h-[150px] w-[150px] rounded-lg shadow object-cover"
            />
            <div className="text-center">
              <p className="heading text-[#EC5929] text-lg">
                Rev. Daniel Demola Fajuyagbe
              </p>
              <p className="text-xs font-medium tracking-wide text-neutral-700">
                Assisting Priest
              </p>
            </div>
          </div>
          <div className="clergy">
            <img
              src={priestsImgs.length > 0 ? priestsImgs[2] : ""}
              alt="Rev. James Ajokolo"
              className="h-[150px] w-[150px] rounded-lg shadow object-cover object-top"
            />
            <div className="text-center">
              <p className="heading text-[#EC5929] text-lg">
                Rev. James Oladimeji Ajoloko
              </p>
              <p className="text-xs font-medium text-neutral-700">
                Assisting Priest
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeClergy;
