import React, { useEffect, useState } from "react";
import Collage from "../components/Collage";
import { allPromiseResults } from "../firebase-results/firebase-results";
import { Link } from "react-router-dom";

function History({historyImgs}) {
  const [historyImages, setHistoryImages] = useState(null);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const getGallery = async () => {
      setHistoryImages(historyImgs);
    };
    getGallery();
  }, []);
  return (
    <div
      id="history"
      className="h-max p-3 min-h-[410px] grid place-items-center mb-8"
    >
      <div className=" flex flex-col mt-32 items-center gap-[1.5rem] text-black w-11/12 md:w-9/12">
        <div className="grid gap-2 mb-4 place-items-center">
          <p className="text-3xl text-center text-black heading">Our History</p>
          <div className="bg-[#EC5929] h-1 w-10 rounded-md"></div>
        </div>
        <div
          className={`grid ${
            historyImages?.length > 0 && "md:grid-cols-2"
          } gap-8`}
        >
          <div
            className={`flex flex-col gap-4 justify-center items-center ${
              historyImages?.length > 0 && "md:items-start"
            } order-last md:order-first text-center`}
          >
            <p className="text-sm leading-[32px] text-left">
              The members of the Church Missionary Society of St. Jude’s
              Anglican Church, Ebute-Metta, Lagos established St. Jude’s
              Anglican Church Shangisha/Magodo primarily through their
              evangelical fervour in March 1988. The Church held its first
              service on Sunday 13 March 1988 in an open space between houses
              number 7 and 9 Onibeju Street, Shangisha belonging to Messrs. J.O.
              Ogunfowoke and J.O. Adewunmi respectively, which about 64 people
              attended (mainly CMS Ebute-Metta members).
            </p>
            <Link
              to="/about"
              onClick={handleClick}
              className="bg-transparent border border-[#EC5929] text-[#EC5929] w-max px-4 py-2 rounded-md text-sm font-medium shadow"
            >
              Read More
            </Link>
          </div>
          {historyImages?.length > 0 && <Collage images={historyImages} />}
          {/* <Collage images={historyImages} /> */}
        </div>
      </div>
    </div>
  );
}

export default History;
