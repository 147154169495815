import React, { useEffect, useState } from "react";
// import Navbar from "../components/Nav";
import ServiceTimes from "../sections/servicetimes";
import NewsUpdates from "../sections/news";
import Sunday from "../sections/sundayservice";
import HomeClergy from "../sections/home-clergy";
import History from "../sections/history";
import Anthem from "../sections/anthem";
import Resources from "../sections/resources";
import Footer from "../sections/footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import Formtabs from "../sections/formtabs";
import Sendtabs from "../sections/sendtabs";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Gallery from "../sections/gallery";

function Home({
  loading,
  serviceTime,
  priestsImgs,
  historyImgs,
  homeBackground,
}) {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [show, setShow] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY > 600) {
      setShow(true);
    } else if (window.scrollY <= 500) {
      setShow(false);
    }
    setLastScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  //carousel
  const [bg, setBg] = useState("");
  let bgs = [];
  homeBackground.map((index, images) => {
    bgs.push(images);
    return index;
  });
  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setBg(bgs[currentIndex]);
      currentIndex = (currentIndex + 1) % bgs.length;
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 3, ease: "easeOut" }}
    >
      <div className={`w-full relative ${show && "opacity-0"}`}>
        {loading ? (
          <div className="bg-home w-full bg-cover bg-fixed h-[100vh] sm:min-h-[100vh] bg-top bg-no-repeat"></div>
        ) : (
          <Carousel
            infiniteLoop
            showArrows={true}
            showStatus={false}
            showThumbs={true}
            showIndicators={false}
            autoPlay={true}
            interval={5000}
            transitionTime={2000}
          >
            {homeBackground.map((image, index) => (
              <div
                key={index}
                style={{ backgroundImage: `url('${image}')` }}
                className="bg-center w-full bg-fixed bg-no-repeat bg-cover h-[100vh]"
              />
            ))}
          </Carousel>
        )}
        {/* <div
            style={{ backgroundImage: `url('${bg}')` }}
            className="w-full bg-cover bg-fixed h-[90vh] sm:min-h-[70vh] bg-top bg-no-repeat"
          ></div> */}
        {/* <div
          className={`${bg} w-full bg-cover bg-fixed h-[90vh] bg-top bg-no-repeat`}
        ></div> */}
        <div className="bg-black/50 absolute top-0 left-0 w-full h-[100vh] sm:min-h-[100vh] text-white p-6 sm:p-10 flex flex-col md:pl-32 justify-center">
          <p className="mt-3 text-sm font-bold tracking-wide uppercase">
            Diocese of lagos west
          </p>
          <p className="text-5xl sm:text-7xl sm:leading-[6rem] sm:max-w-3xl heading">
            St Jude's Anglican Church,
            <br /> Magodo
          </p>
          <p className="mt-6 tracking-wide sm:max-w-xl ">
            Seeking a deeper connection with God? Look no further! St. Jude's
            Anglican Church welcomes you with open arms. We believe in the power
            of faith to transform lives and bring hope. Join us on our journey
            of spiritual growth and discover the joy of belonging to a
            supportive church family.
          </p>
          <div className="flex flex-wrap items-center gap-2 mt-10 sm:gap-4">
            <a
              href="https://www.youtube.com/channel/UCBh9i-y7Nd1jrbC4O5ARXeg"
              target="_blank"
              rel="noreferrer"
              className="px-6 py-3 text-sm font-medium bg-black rounded-md hover:bg-white hover:text-black"
            >
              Watch us on Youtube{" "}
              <i className="ml-1 fa-brands fa-youtube text-rose-600"></i>
            </a>
            <a
              href="https://www.facebook.com/stjudesmagodo"
              target="_blank"
              rel="noreferrer"
              className="px-6 py-3 text-sm font-medium bg-black rounded-md hover:bg-white hover:text-black"
            >
              Watch us on Facebook
              <i className="ml-1 fa-brands fa-square-facebook text-sky-600"></i>
            </a>
          </div>
        </div>
      </div>

      {/* <div className="bg-[#EC5929] h-max px-2 py-4 md:p-3 min-h-[410px] grid place-items-center mb-8">
        <div className=" flex flex-col items-center gap-[1.5rem] text-white w-9/12">
          <div className="grid gap-2 place-items-center">
            <p className="text-3xl text-white heading">Welcome</p>
            <div className="w-10 h-1 bg-white rounded-md"></div>
          </div>
          <p className="text-sm leading-[32px] text-center">
            The members of the Church Missionary Society of St. Jude’s Anglican
            Church, Ebute-Metta, Lagos established St. Jude’s Anglican Church
            Shangisha/Magodo primarily through their evangelical fervour in
            March 1988. The Church held its first service on Sunday 13 March
            1988 in an open space between houses number 7 and 9 Onibeju Street,
            Shangisha belonging to Messrs. J.O. Ogunfowoke and J.O. Adewunmi
            respectively, which about 64 people attended (mainly CMS Ebute-Metta
            members)
          </p>
          <Link
            to="/about"
            onClick={handleClick}
            className="bg-white text-[#EC5929] w-max px-4 py-2 rounded-md text-sm font-medium shadow-xl"
          >
            Read More
          </Link>
        </div>
      </div>
      <div className="grid place-items-center w-full p-6 mt-24 gap-[1.5rem]">
        <div className="flex flex-col gap-8 p-8 items-center justify-center rounded-xl custom-gradient text-white w-11/12 md:w-9/12 min-h-[300px]">
          <h2 className="font-[PlayfairDisplay] text-2xl text-center leading-[2rem] md:leading-[3.5rem] md:text-[3rem]">
            Worship with us anywhere and anytime
          </h2>
          <div className="flex flex-wrap justify-center gap-4 align-center">
            <div
              className="flex transition-all justify-center flex-nowrap align-center 
            rounded-[.45rem] wyt cursor-pointer text-[1.125rem] w-[16.0625rem] h-[4.25rem] bg-black 
            custom-box-shadow-gradient hover:brightness-[1.25]"
            >
              <a
                className="grid grid-flow-col gap-4 place-items-center wyt flex-nowrap align-center"
                href="https://www.youtube.com/channel/UCBh9i-y7Nd1jrbC4O5ARXeg"
                target="_blank"
                rel="noreferrer"
              >
                <p>Watch us on Youtube</p>
                <i className="fa-brands fa-youtube !text-[20px] yt"></i>
              </a>
            </div>

            <div
              className="flex transition-all justify-center flex-nowrap
              align-center rounded-[.45rem] text-[1.125rem] w-[16.0625rem]
              h-[4.25rem] wfb cursor-pointer custom-box-shadow-gradient
              hover:brightness-[1.25]"
            >
              <a
                className="grid grid-flow-col gap-4 place-items-center flex-nowrap align-center"
                href="https://www.facebook.com/stjudesmagodo"
                target="_blank"
                rel="noreferrer"
              >
                <p>Watch us on</p>
                <i className="fa-brands fa-square-facebook !text-[20px] fb"></i>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <ServiceTimes serviceTime={serviceTime} />
      <NewsUpdates />
      <Sunday />
      <HomeClergy priestsImgs={priestsImgs} />
      <Gallery />
      <History historyImgs={historyImgs} />
      <Anthem />
      <Resources />
      <Sendtabs />
      {/* <Footer /> */}
    </motion.div>
  );
}

export default Home;
