import React, { useEffect, useState } from "react";

function ServiceTimes({ serviceTimeImg }) {
  const [serviceImage, setServiceImage] = useState(null);
  useEffect(() => {
    const getImages = () => {
      setServiceImage(serviceTimeImg);
    };
    getImages();
  });
  return (
    <div
      id="service"
      className="bg-white text-white h-max p-6 min-h-[500px] grid gap-3 place-items-center mb-8"
    >
      <div className="grid gap-3 place-items-center mt-32 w-full sm:w-9/12">
        <div className="sm:w-3/5 grid gap-2">
          <p className="text-3xl capitalize text-black heading text-center">
            Weekly activities
          </p>
        </div>
        <div className="pt-10 border-t border-[#EC5929] w-full">
          <div className="grid sm:grid-cols-2 min-h-[400px] h-max overflow-hidden shadow-2xl rounded-xl">
            {serviceImage?.length > 0 ? (
              <img
                src={serviceImage[0]}
                loading="lazy"
                alt=""
                className="hidden lg:rounded-l-xl lg:block basis-1/2"
              />
            ) : (
              <div className="bg-[url('https://images.unsplash.com/photo-1594321610209-b0f463805378')] bg-cover relative bg-center hidden sm:block">
                <div className="absolute top-0 left-0 h-full w-full bg-black/20"></div>
              </div>
            )}

            <div className="bg-[#A84F33] grid sm:grid-cols-2 gap-4 divide-y sm:divide-y-0 divide-neutral-100/40 *:text-center *:md:text-left md:gap-3 p-4">
              {/* <div className="flex flex-col gap-3 font-medium">
                <div className="text-center md:text-left">
                  <span className="program text-xs">Sunday Service</span>
                  <p className="text-xl" style={{ fontFamily: "DM Sans" }}>
                    8:00am
                  </p>
                </div>
                <div className="text-center md:text-left">
                  <span className="program text-xs font-medium">
                    Monday Evangelical Fellowship
                  </span>
                  <p className="text-xl" style={{ fontFamily: "DM Sans" }}>
                    6:00pm - 7:00pm
                  </p>
                </div>
                <div className="text-center md:text-left">
                  <span className="program text-xs">Tuesday Bible Study</span>
                  <p className="text-xl" style={{ fontFamily: "DM Sans" }}>
                    6:00pm - 7:00pm
                  </p>
                </div>
                <div className="text-center md:text-left">
                  <span className="program text-xs font-medium">
                    Wednesday Communion Service
                  </span>
                  <p className="text-xl" style={{ fontFamily: "DM Sans" }}>
                    6:00pm - 7:00pm
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-3 font-medium">
                <div className="text-center md:text-left">
                  <span className="program text-xs font-medium">
                    Thursday Women’s Prayer Meeting
                  </span>
                  <p className="text-xl" style={{ fontFamily: "DM Sans" }}>
                    10:00am - 12:00pm
                  </p>
                </div>
                <div className="text-center md:text-left">
                  <span className="program text-sm font-medium">
                    Saturday Choir Practice
                  </span>
                  <p className="text-xl" style={{ fontFamily: "DM Sans" }}>
                    6:00pm - 8:00pm
                  </p>
                </div>
                <div className="text-center md:text-left">
                  <span className="program text-xs font-medium">
                    Daily Prayer Meeting
                  </span>
                  <p className="text-xl" style={{ fontFamily: "DM Sans" }}>
                    6:00am - 6:30am
                  </p>
                </div>
                <div className="text-center md:text-left">
                  <span className="program text-xs font-medium">
                    Lenten Revival (Everyday till March 31st 2024)
                  </span>
                  <p className="text-xl" style={{ fontFamily: "DM Sans" }}>
                    6:00pm - 7:00pm
                  </p>
                </div>
              </div> */}
              <div className="svc">
                <span className="program">Sunday Service (English)</span>
                <p className="time">8:00am</p>
              </div>
              <div className="svc">
                <span className="program">Sunday Evensong</span>
                <p className="time">6:00pm - 6:30pm</p>
              </div>
              <div className="svc">
                <span className="program">Monday Evangelic Fellowship</span>
                <p className="time">6:00pm - 7:00pm</p>
              </div>
              <div className="svc">
                <span className="program">Tuesday Bible Study</span>
                <p className="time">6:00pm - 7:00pm</p>
              </div>
              <div className="svc">
                <span className="program">
                  Wednesday Mountain Moving Meeting
                </span>
                <p className="time">6:00pm - 7:00pm</p>
              </div>
              <div className="svc">
                <span className="program">Thursday Women Prayer Meeting</span>
                <p className="time">10:00am - 12:00pm</p>
              </div>
              <div className="svc">
                <span className="program">Saturday Choir Practice</span>
                <p className="time">6:00pm - 8:00pm</p>
              </div>
              <div className="svc">
                <span className="program">Daily Prayer Meeting</span>
                <p className="time">8:00am</p>
              </div>
              <div className="svc col-span-2 w-full mt-3">
                <p className="capitalize font-medium">Other activities</p>
                <div className="grid sm:grid-cols-2 gap-3 mt-2 divide-y sm:divide-y-0 divide-neutral-100/40">
                  <div className="flex flex-col gap-1">
                    <span className="program">God of miracle hour</span>
                    <span className="text-sm capitalize font-medium">
                      1st day of the month{" "}
                    </span>
                    <p className="time">6:00am - 7:00am</p>
                  </div>
                  <div className="flex flex-col gap-1 py-2">
                    <span className="program">Monthly vigil</span>
                    <p className="time">Last Fridays of the Month</p>
                  </div>
                  <div className="flex flex-col gap-1 py-2">
                    <span className="program">Boys Brigade</span>
                    <span className="text-sm capitalize font-medium">
                      Every sunday
                    </span>
                    <p className="time">3:00pm</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceTimes;
